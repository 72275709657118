@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';
@import './node_modules/bootstrap/scss/mixins';
@import './node_modules/bootstrap/scss/reboot';
@import './node_modules/bootstrap/scss/type';
@import './node_modules/bootstrap/scss/grid';
@import './node_modules/bootstrap/scss/utilities';
@import './node_modules/bootstrap/scss/carousel';
@import './node_modules/bootstrap/scss/buttons';
@import './node_modules/bootstrap/scss/images';
@import './node_modules/bootstrap/scss/tables';

button:focus {
    outline: none;
}

body {
    background-color: var(--neutral-theme)!important;
    color: var(--gray500)
}
